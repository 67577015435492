import React from 'react';

import { navigate } from 'gatsby';

const SequinProject = () => {
  React.useEffect(() => {
    navigate('/sequin-university');
  }, []);

  return <></>;
};

export default SequinProject;
